<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('questions') }}</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">
                        <router-link :to="{ name: 'dashReports' }">{{
                           $t("dashboard")
                        }}</router-link>
                     </li>
                     <li class="breadcrumb-item active">{{ $t('questions') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <!-- /.row -->
            <div class="row">
               <div class="col-12">
                  <div class="card">
                     <div class="card-header row">
                        <div class="col d-flex align-items-start justify-content-start">
                           <span :class="switchSpanClass" style="font-weight: 700;">{{ $t('only_the_female') }}{{ ' ' }}{{ $t('no') }}{{ ' ' }}{{
                              $t('answered_female') }}</span>
                           <div class="col-2">
                              <div class="form-group">
                                 <div class="custom-control custom-switch custom-switch-on-success">
                                    <input type="checkbox" v-model="isAllQuestions" class="custom-control-input"
                                       id="comentSwitch">
                                    <label :class="[switchLabelClass, 'custom-control-label']"
                                       for="comentSwitch">
                                       {{ $t('all_female') }}
                                    </label>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="card-tools col d-flex align-items-start justify-content-end">
                           <div class="input-group input-group-sm pt-2" style="width: 350px;">
                              <input
                                 type="text"
                                 name="table_search"
                                 class="form-control float-right"
                                 placeholder="Buscar"
                                 v-model="searchQuery"
                                 @input="handleDelayedSearch">

                              <div class="input-group-append">
                                 <button type="submit" class="btn btn-default">
                                    <i class="fas fa-search"></i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else class="card-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                           <thead>
                              <tr>
                                 <th>Pergunta</th>
                                 <th>Data de Criação</th>
                                 <th>Ação</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="(question, index) in questions[currentPage - 1]" :key="index">
                                 <td style="max-width: 300px;" :class="[question.alreadyAnswered ? 'text-gray' : '']">
                                    <div>
                                       <p style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                          {{ question.question }}
                                       </p>
                                    </div>
                                 </td>
                                 <td :class="[question.alreadyAnswered ? 'text-gray' : '']">
                                    {{ question.createdAt }}
                                 </td>
                                 <td>
                                    <div class="row">
                                       <router-link v-if="question.alreadyAnswered"
                                          :to="{ name: 'replyQuestion', params: { uuid: question.uuid } }"
                                          class="btn btn-sm btn-outline-primary text-bold">
                                          <i class="pt-1 fas fa-pencil-alt"></i>
                                          {{ $t('edit') }}
                                       </router-link>
                                       <router-link v-else :to="{ name: 'replyQuestion', params: { uuid: question.uuid } }"
                                          class="btn btn-sm btn-outline-primary text-bold">
                                          <i class="pt-1 fas fa-comments"></i>
                                          {{ $t('reply') }}
                                       </router-link>
                                       <button
                                          class="ml-3 btn btn-sm btn-outline-danger text-bold"
                                          @click="showConfirmationModal(question)"
                                       >
                                          <i class="pt-1 fas fa-trash-alt"></i>
                                          {{ $t('exclude') }}
                                       </button>
                                    </div>
                                 </td>
                                 <ConfirmationModal
                                    ref="modalConfirmation"
                                    :modalId="modalConfirmationActionId + question.uuid"
                                    @actionConfirmed="deleteQuestion"
                                    @actionCancelled="hideConfirmationModal"
                                 />
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <!-- /.card-body -->

                     <div class="card-footer clearfix">
                        <pagination
                           v-if="!isSearching"
                           :totalPages="totalPages"
                           :currentPage="currentPage"
                           :isFirstPage="isFirstPage"
                           :isLastPage="isLastPage"
                           :adjacentPages="adjacentPages"
                           :tokenPages="tokenPages"
                           :isLoading="isLoadingPagination"
                           @page-selected="handlePageSelected"
                        />
                     </div>
                     <!-- /.card-footer -->

                  </div>
                  <!-- /.card -->
               </div>
            </div>
            <!-- /.row -->
         </div>
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
import { useToast } from "vue-toastification";
import {mapActions, mapMutations, mapState} from "vuex";
import Pagination from "../../../components/Pagination.vue";
import ConfirmationModal from "../../../components/ConfirmationModal.vue";
import questionService from "../services/question-service";

export default {
   name: "Questions",
   components: {ConfirmationModal, Pagination},
   setup() {
      const toast = useToast();
      return { toast };
   },
   data() {
      return {
         isAllQuestions: false,
         searchTimeout: null,
         searchQuery: "",
         isSearching: false,
         isLoadingPagination: false,
         adjacentPagesCount: 2,
         questionSelected: null,
         modalConfirmationActionId: "modal-confirmation-action-",
      }
   },
   computed: {
      ...mapState({
         questions: state => state.question.questions.data,
         isLoading: state => state.question.questions.loading,
         currentPage: state => state.question.questions.pagination.currentPage,
         tokenPages: state => state.question.questions.pagination.tokenPages,
         totalPages: state => state.question.questions.pagination.totalPages
      }),
      switchSpanClass() {
         return !this.isAllQuestions ? 'text-green' : 'text-gray';
      },
      switchLabelClass() {
         return this.isAllQuestions ? 'text-green' : 'text-gray';
      },
      adjacentPages() {
         const currentIndex = this.tokenPages.findIndex((page) => page.page === this.currentPage);

         const startIdx = Math.max(0, currentIndex - this.adjacentPagesCount);
         const endIdx = Math.min(this.tokenPages.length - 1, currentIndex + this.adjacentPagesCount);

         const pages = this.tokenPages.slice(startIdx, endIdx + 1);

         return pages;
      },
      isFirstPage() {
         return this.currentPage === 1;
      },
      isLastPage() {
         return this.currentPage === this.totalPages;
      }
   },
   created() {
      this.loadQuestions();
   },
   watch: {
      isAllQuestions() {
         this.loadQuestions(this.isAllQuestions);
      }
   },
   methods: {
      ...mapActions(['getAllPaginatedQuestions', 'searchQuestions']),
      ...mapMutations({
         setCurrentPage: 'SET_QUESTION_CURRENT_PAGE'
      }),
      async loadQuestions(isAllQuestions) {
         this.isLoadingPagination = true;
         await this.getAllPaginatedQuestions({ isAllQuestions: isAllQuestions });
         this.isLoadingPagination = false;
      },
      async handleDelayedSearch() {
         clearTimeout(this.searchTimeout);

         this.searchTimeout = setTimeout(async () => {
            if (this.searchQuery.length > 0) {
               this.isSearching = true;
               await this.searchQuestions(this.searchQuery);
            } else {
               this.isSearching = false;
               await this.getAllPaginatedQuestions();
            }
         }, 1000);
      },
      async handlePageSelected (action) {
         let pageIndex;

         if (action === 'first' && !this.isFirstPage) {
            pageIndex = 1;
         } else if (action === 'prev' && !this.isFirstPage) {
            this.setCurrentPage(this.currentPage - 1);
            pageIndex = this.currentPage;
         } else if (action === 'next' && !this.isLastPage) {
            this.setCurrentPage(this.currentPage + 1);
            pageIndex = this.currentPage;
         } else if (action === 'last' && !this.isLastPage) {
            pageIndex = this.totalPages;
         } else if (typeof action === 'number') {
            pageIndex = action;
         }

         if (pageIndex) {
            const targetPage = this.tokenPages.find((page) => page.page === pageIndex);

            if (targetPage) {
               this.setCurrentPage(targetPage.page);
            }
         }
      },
      async deleteQuestion() {
         try {
            this.$refs.modalConfirmation.loading = true
            await questionService.destroy(this.questionSelected.uuid);

            this.hideConfirmationModal();
            this.toast.success('O item foi excluído');
            await this.loadQuestions(this.isAllQuestions);
         } catch (error) {
            this.hideConfirmationModal()

            this.toast.error(this.$t('an_error_occurred'));
         }
      },
      showConfirmationModal(item) {
         this.questionSelected = item
         $(`#${this.modalConfirmationActionId}${this.questionSelected.uuid}`).modal('show');
      },
      hideConfirmationModal() {
         $(`#${this.modalConfirmationActionId}${this.questionSelected.uuid}`).modal('hide')
         $('.modal-backdrop').remove();
         this.questionSelected = null
      },
   },
};
</script>

<style scoped></style>
